import { Box, CircularProgress, SxProps } from "@mui/material";
type LoaderType = {
  sx?: SxProps;
  size?: number;
  color?: string;
};
function Loader({
  sx,
  size,
  color
}: LoaderType) {
  return <Box sx={{
    pt: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    ...sx
  }} data-sentry-element="Box" data-sentry-component="Loader" data-sentry-source-file="Loader.tsx">
            <CircularProgress size={size} sx={{
      color
    }} data-sentry-element="CircularProgress" data-sentry-source-file="Loader.tsx" />
        </Box>;
}
export default Loader;